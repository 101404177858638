var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_report_history_batch") } },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            "label-align": "left",
            model: _vm.form,
            rules: _vm.rules,
            "label-col": { span: 4 },
            "wrapper-col": { span: 14 }
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { prop: "branchId", label: _vm.$t("lbl_branch") } },
            [
              _c("CSelectBranch", {
                model: {
                  value: _vm.form.branchId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "branchId", $$v)
                  },
                  expression: "form.branchId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: _vm.$t("lbl_product_code"), prop: "productCode" }
            },
            [
              _c("CSelectMasterProductCode", {
                attrs: { "filter-active": false },
                on: {
                  "on-select": function(ref) {
                    var meta = ref.meta

                    return _vm.onSelectProduct(meta, "code")
                  }
                },
                model: {
                  value: _vm.form.productCode,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "productCode", $$v)
                  },
                  expression: "form.productCode"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: _vm.$t("lbl_product_name"), prop: "productName" }
            },
            [
              _c("CSelectMasterProductName", {
                attrs: { "filter-active": false },
                on: {
                  "on-select": function(ref) {
                    var meta = ref.meta

                    return _vm.onSelectProduct(meta, "name")
                  }
                },
                model: {
                  value: _vm.form.productName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "productName", $$v)
                  },
                  expression: "form.productName"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_batch_number"), prop: "batchId" } },
            [
              _c("CSelectBatchNumber", {
                attrs: {
                  disabled: !_vm.form.productId,
                  "by-product": _vm.form.productId
                },
                model: {
                  value: _vm.form.batchId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "batchId", $$v)
                  },
                  expression: "form.batchId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleClear } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        icon: "download",
                        type: "primary",
                        loading: _vm.loading
                      },
                      on: { click: _vm.validateForm }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }